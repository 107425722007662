import React from 'react'
import './Styles/IntelliKits.scss'

import ImageSlider from '../Components/ImageSlider/ImageSlider.js'
import {HashLink} from 'react-router-hash-link'
import ContentPrompt from "../Components/ContentPrompt/index.js"
import ProductCard from "../Components/ProductCard/index.js"
import { AiOutlinePlus } from 'react-icons/ai'

import PLACEHOLDERIMAGE from "../Assets/IntelliKits/PLACEHOLDERIMAGE.png"

import AOS from 'aos'

const introImages = [
  {image: PLACEHOLDERIMAGE}, {image: PLACEHOLDERIMAGE}, {image: PLACEHOLDERIMAGE}
]

const annotatedKitRenders =  [
  {image: PLACEHOLDERIMAGE}, {image: PLACEHOLDERIMAGE}, {image: PLACEHOLDERIMAGE}
]

AOS.init()

class IntelliKits extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {promptEnabled: false, promptName: null, faqEnabled: false}
    this.OpenKitSpecs = this.OpenKitSpecs.bind(this)

  }
  
  OpenKitSpecs() {
    const promptEnabled = this.state.promptEnabled
    if (!promptEnabled) {
      this.setState({promptEnabled: true, promptName: "IntelliKitsInstructions"})
    }
  }

  contentCallback = () => { console.log("callback called"); this.setState({promptEnabled: false}) }

  handleFAQ = (e) => {
    e.target.classList.toggle('active')
    var panel = e.target.nextElementSibling 
    if (panel.style.maxHeight) {
      panel.style.maxHeight = null
    } else {
      panel.style.maxHeight = panel.scrollHeight + "px"
    }
  }
  
  render() {

    let promptObject = (null);
    if (this.state.promptEnabled) {
      promptObject = <ContentPrompt name={this.state.promptName} callback={this.contentCallback}/>
    }

    AOS.init();

    return (
      <>
        <div className='IK-header'>
          <h1>IntelliKits</h1>
          <h2>Our program delivering STEM education to underprivlidged elementary students with interactive kits!</h2>
        </div>
        <div className='IK-content'>
          <div className='about'>
            <h1 data-aos="fade up">What is IntelliKits?</h1>
            <hr />
            <div className='row'>
              <div className='column' data-aos="fade-right">
                  <h1>About</h1>
                  <h2>asdfasdf asdf asdf asdf asdf asdf asdf asdf asdf asdf asdf afd asdfasdf asdf asdf asdf asdf asdf adsf asdf asdf asdf asdf asdf asdf asdf asfd safd asfd afds adfs afsd adsf as asd sad asdf asdf asdf asdf asdf asdf ads asd asfd asdf asdf asd asdf asdfasd asdf</h2>
              </div>
              <div data-aos="fade-left">
                <ImageSlider slides={introImages} />
              </div>
            </div>
            <HashLink smooth to="#exampleKit" data-aos="fade-up">Check out an example kit!</HashLink>
          </div>
          <div className='exampleKit' id="exampleKit" data-aos="fade-up">
            <h1>Example Kit</h1>
            <hr />
            <p>Take a look at an example kit to see what you're getting!</p>
            <ImageSlider slides={annotatedKitRenders} />
            <button onClick={this.OpenKitSpecs} className='instructionsButton' data-aos="fade-up">View Instructions</button>
          </div>
          <div className='moreInfo'data-aos="fade-up">
            <h1>Program Information</h1>
            <hr />
            <div className='row'>
              <ProductCard clickHandler={null} circleImg={PLACEHOLDERIMAGE} bannerImg={PLACEHOLDERIMAGE} name="Release Schedule" desc="DESCRIPTION HERE"/>
              <div className='container'>
                <div className='center'>
                  <h1>Any more questions?</h1>
                  <a href="#faq"><button className='faqButton'>Click here!</button></a>
                </div>
              </div> 
              <ProductCard clickHandler={null} circleImg={PLACEHOLDERIMAGE} bannerImg={PLACEHOLDERIMAGE} name="Other Information" desc="DESCRIPTION HERE"/>
            </div>
          </div>
          <div className='faq' id="faq" data-aos="fade-up">
            <h1>FAQ</h1>
            <hr />
            <h2 className='desc'>Questions? Concerns? We're here to help! Below are a few of the most asked questions. If you need any additional help, feel free to visit our contacts page!</h2>
            <button className='accordion' onClick={this.handleFAQ} >Q1 PLACEHOLDER<AiOutlinePlus size="1.25em" /></button>
            <div className='panel'>
              <p>A1 PLACEHOLDER</p>
            </div>
            <button className='accordion' onClick={this.handleFAQ} >Q2 PLACEHOLDER<AiOutlinePlus size="1.25em" /></button>
            <div className='panel'>
              <p>A2 PLACEHOLDER</p>
            </div>
            <button className='accordion' onClick={this.handleFAQ} >Q3 PLACEHOLDER<AiOutlinePlus size="1.25em" /></button>
            <div className='panel'>
              <p>A3 PLACEHOLDER</p>
            </div>
          </div>
        </div>
        {promptObject}
      </>
    )
  }
}

export default IntelliKits